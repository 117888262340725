<template>
  <div>
    <div class="index_box" ref="indexRef">
      <div class="index_title">
        <el-row>
          <el-col :span="15">
            <img class="title_logo" src="../assets/images/white.png" alt="" />
          </el-col>
          <el-col class="title_product" :span="3"
            ><div @click="product">产品</div>
          </el-col>
          <el-col class="title_contact" :span="3">
            <div @click="relation">联系我们</div>
          </el-col>
          <el-col class="title_login" :span="3">
            <div @click="login">登录</div>
          </el-col>
        </el-row>
      </div>
      <div class="index_bannar">
        <p class="bannar_title" style="color: #ffffff">用数据和事实省钱！</p>
        <p class="bannar_text" style="color: #ffffff">
          成本魔豆是产品成本计算工具，帮助企业实现：采购成本透明化，快速准确报价，不同场景成本分析。
        </p>
        <p class="bannar_btn" @click="relation">联系我们，免费试用</p>
      </div>

      <div class="index_describe1" id="index1">
        <el-row>
          <el-col :span="12">
            <img class="describe1_img" src="../assets/images/img1.png" alt="" />
          </el-col>
          <el-col :span="12">
            <div class="text_box">
              <p class="describe1_title">准确计算，快速响应</p>
              <p class="describe1_paragraph">系统化模型</p>
              <p class="describe1_text">基于作业成本法的计算工具；</p>
              <p class="describe1_text">根据事实数据，显现真实成本。</p>
              <p class="describe1_paragraph">性能卓越，即时运算</p>
              <p class="describe1_text">高性能服务器，即时运算，所见即所得。</p>
              <p class="describe1_paragraph">支持用户高度自定义</p>
              <p class="describe1_text">内置强大数据库，同时支持用户自定义。</p>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="index_describe2">
        <el-row>
          <el-col :span="12">
            <div class="text_box">
              <p class="describe2_title">强大的数据库，每月更新</p>
              <p class="describe2_text">10+行业</p>
              <p class="describe2_text">20+国家，100+地区</p>
              <p class="describe2_text">100+产品成本模板</p>
              <p class="describe2_text">10000+原材料数据</p>
              <p class="describe2_text">10000+机器数据</p>
              <p class="describe2_text">100000+标准元器件数据</p>
            </div>
          </el-col>
          <el-col :span="12">
            <img class="describe2_img" src="../assets/images/img2.png" alt="" />
          </el-col>
        </el-row>
      </div>

      <div class="index_describe3">
        <el-row>
          <el-col :span="12">
            <img class="describe3_img" src="../assets/images/img3.png" alt="" />
          </el-col>
          <el-col :span="12">
            <div class="text_box">
              <p class="describe3_title">广泛的应用场景</p>
              <p class="describe3_text">
                新产品应该成本计算，报价，成本设计，价值工程
              </p>
              <p class="describe3_text">
                现有产品成本分析，降价机会识别，价格谈判
              </p>
              <p class="describe3_text">产品成本预测，敏感性分析</p>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="index_contact" id="index2">
        <p class="contact_title">
          <i class="el-icon-message" />
          <span>联系我们</span>
        </p>

        <el-form ref="userInfo" :model="userInfo" style="padding: 0 200px">
          <el-form-item
            prop="userName"
            :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur',
            }"
          >
            <el-input
              style="margin-top: 30px"
              placeholder="* 请输入姓名"
              v-model="userInfo.userName"
              clearable
            />
          </el-form-item>

          <el-form-item
            prop="companyEmail"
            :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur',
            }"
          >
            <el-input
              style="margin-top: 30px"
              placeholder="* 请输入公司邮箱"
              v-model="userInfo.companyEmail"
              clearable
            />
          </el-form-item>

          <el-form-item
            prop="phone"
            :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur',
            }"
          >
            <el-input
              style="margin-top: 30px"
              placeholder="* 请输入联系电话"
              v-model="userInfo.phone"
              clearable
            />
          </el-form-item>

          <el-form-item
            prop="content"
            :rules="{
              required: true,
              message: '不能为空',
              trigger: 'blur',
            }"
          >
            <el-input
              style="margin-top: 30px"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="* 请输入留言内容"
              v-model="userInfo.content"
            >
            </el-input>
          </el-form-item>
        </el-form>

        <p class="contact_btn" @click="submit">提交</p>
      </div>
    </div>

    <div class="records_box">
      <el-row>
        <el-col :span="4"> {{ configData1.homePageContent }}</el-col>
        <el-col :span="12"
          ><div class="toContent">
            <span @click="toHomePageContent1">{{
              configData2.homePageContent
            }}</span
            ><span @click="toHomePageContent2">{{
              configData5.homePageContent
            }}</span>
          </div>
        </el-col>
        <el-col :span="4">{{ configData3.homePageContent }}</el-col>
        <el-col :span="4">{{ configData4.homePageContent }}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
        userName: "",
        companyEmail: "",
        phone: "",
        content: "",
      },

      configData1: "",
      configData2: "",
      configData3: "",
      configData4: "",
      configData5: "",
    };
  },

  created() {
    this.getConfig();
  },

  methods: {
    toHomePageContent1() {
      window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
    },

    toHomePageContent2() {
      window.location.href =
        "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402005935";
    },

    product() {
      document.getElementById("index1").scrollIntoView();
    },

    relation() {
      document.getElementById("index2").scrollIntoView();
    },

    login() {
      window.open("https://my.costmodel.cn/product", "_self");
    },

    getConfig() {
      this.$http.homePageConfig().then((response) => {
        console.log(response);
        this.configData1 = response.data[0];
        this.configData2 = response.data[1];
        this.configData3 = response.data[2];
        this.configData4 = response.data[3];
        this.configData5 = response.data[4];
      });
    },

    submit() {
      this.$refs["userInfo"].validate((valid) => {
        if (valid) {
          this.$http.userLeaveMessage(this.userInfo).then((response) => {
            this.$message({
              message: response.msg,
              type: "success",
            });
            this.userInfo.userName = "";
            this.userInfo.companyEmail = "";
            this.userInfo.phone = "";
            this.userInfo.content = "";
          });
        } else {
          this.$message.error("请输入必填项！");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.toContent {
  cursor: pointer;
}
.index_box {
  width: 1200px;
  height: 100%;
  margin: 0 auto;

  .index_title {
    width: 1200px;
    height: 60px;
    background: #304156;
    .title_logo {
      width: 189px;
      height: 60px;
    }
    .title_product {
      text-align: center;
      line-height: 60px;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;
    }
    .title_contact {
      text-align: center;
      line-height: 60px;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;
    }
    .title_login {
      text-align: center;
      line-height: 60px;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .index_bannar {
    width: 1200px;
    height: 800px;
    background: url("../assets/images/bannar.jpg") no-repeat;
    background-size: cover;

    .bannar_title {
      padding-top: 60px;
      font-size: 50px;
      font-weight: 800;
      color: #304156;
      padding-left: 320px;
    }

    .bannar_text {
      padding-top: 20px;
      font-size: 20px;
      color: #304156;
      font-weight: 600;
      line-height: 30px;
      padding: 30px 240px 0 320px;
    }

    .bannar_btn {
      width: 300px;
      height: 60px;
      margin: 80px auto;
      background: #304156;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      line-height: 60px;
      border-radius: 20px;
      cursor: pointer;
    }
  }

  .index_describe1 {
    width: 1200px;
    height: 500px;
    margin-top: 50px;

    .describe1_img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      display: block;
    }
    .text_box {
      width: 100%;
      height: 100%;
      padding: 15px 15%;
      .describe1_title {
        font-size: 30px;
        font-weight: 700;
        color: #304156;
      }

      .describe1_paragraph {
        margin-top: 50px;
        font-size: 24px;
        font-weight: 600;
        color: #304156;
      }

      .describe1_text {
        font-size: 20px;
        color: #304156;
        padding-top: 5px;
      }
    }
  }

  .index_describe2 {
    width: 1200px;
    height: 500px;
    margin-top: 50px;

    .text_box {
      width: 100%;
      height: 100%;
      padding: 15px 15%;

      .describe2_title {
        font-size: 28px;
        color: #304156;
        font-weight: 600;
        margin: 30px 0;
      }

      .describe2_text {
        font-size: 20px;
        color: #304156;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }

    .describe2_img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      display: block;
    }
  }

  .index_describe3 {
    width: 1200px;
    height: 500px;
    margin-top: 50px;

    .describe3_img {
      width: 100%;
      height: 500px;
      object-fit: cover;
      display: block;
    }

    .text_box {
      width: 100%;
      height: 100%;
      padding: 15px 15%;

      .describe3_title {
        font-size: 28px;
        color: #304156;
        font-weight: 600;
        margin: 30px 0;
      }

      .describe3_text {
        font-size: 20px;
        color: #304156;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
  }

  .index_contact {
    width: 1200px;
    height: 600px;
    background: #fcfcfc;
    border-radius: 10px;
    margin-top: 50px;
    .contact_title {
      text-align: center;
      padding-top: 20px;
      i {
        font-size: 50px;
        font-weight: 800;
        padding-right: 20px;
        color: #304156;
        transform: translateY(8px);
      }

      span {
        font-size: 28px;
        font-weight: 800;
        color: #304156;
      }
    }

    .contact_btn {
      width: 200px;
      height: 60px;
      margin: 30px auto;
      background: #304156;
      color: #ffffff;
      font-size: 24px;
      line-height: 60px;
      text-align: center;
      border-radius: 15px;
    }
  }
}
.records_box {
  width: 100%;
  height: 100px;
  margin-top: 50px;
  font-size: 16px;
  text-align: center;
}
</style>

